import { OrdersAPI } from "@api-clients/orders";
import { CustomerState } from "@./customer-state";
import { ExtraSubscriptionStatus } from "libs/customer-state/src/lib/types";
import { CustomerAPI } from "@api-clients/customer";
import { OrdersState } from "libs/orders-state/src";

export type NibblesUpsellType = "chicken" | "salmon" | "none";

export interface CheckNibblesUpsellConfig {
  minBoxesRequired?: number;
}

/**
 * Determines what type of Nibbles upsell (if any) the user is eligible for.
 *
 * @param {CustomerAPI} customerApi - An instance of the CustomerAPI, used to fetch customer details.
 * @param {Object} [config] - Optional configuration for the checks.
 * @param {number} [config.minBoxesRequired=3] - The minimum number of boxes required before offering the upsell.
 * @returns {Promise<"chicken"|"salmon"|"none">} The upsell type:
 *  - `"chicken"` if the user is eligible for chicken nibbles,
 *  - `"salmon"` if the user is eligible for salmon nibbles,
 *  - `"none"` if no upsell should be shown.
 *
 * @example
 * import { checkNibblesUpsellEligibility } from "@/utils/nibbles-eligibility";
 *
 * const upsellType = await checkNibblesUpsellEligibility(customerApi, { minBoxesRequired: 2 });
 * if (upsellType === "chicken") {
 *   // Show chicken modal
 * }
 */
export async function checkNibblesUpsellEligibility(
  customerApi: CustomerAPI,
  ordersAPI: OrdersAPI,
  config: CheckNibblesUpsellConfig = {},
): Promise<NibblesUpsellType> {
  if (!customerApi) return "none";

  const { minBoxesRequired = 1 } = config; // Default to 2 or more boxes including the trial box hence why it is one less.
  const customerState = new CustomerState(customerApi);
  const ordersState = new OrdersState(ordersAPI);

  const customerStatus = await customerState.getCustomerStatus();

  // Check nibbles subscription status
  const isActiveNibbles = ![
    ExtraSubscriptionStatus.Never,
    ExtraSubscriptionStatus.Inactive,
  ].includes(customerStatus.nibblesStatus);

  // Check if the user has purchased nibbles in the last 3 months
  let hasPurchasedNibblesIn3Months = false;
  try {
    hasPurchasedNibblesIn3Months = await ordersState.hasPurchasedNibblesInXMonths(3);
  } catch (e) {
    console.error("Error checking if user has purchased nibbles in the last 3 months", e);
    return "none";
  }

  if (isActiveNibbles || hasPurchasedNibblesIn3Months) {
    return "none";
  }

  // Check if the user has at least `minBoxesRequired` boxes
  const boxNumbers = await customerState.getBoxNumbersPerCat();
  const meetsMinBoxThreshold = Array.from(boxNumbers.values()).some(
    (boxNumber) => +boxNumber >= minBoxesRequired,
  );

  if (!meetsMinBoxThreshold) {
    return "none";
  }

  // Check if the user is allergic to chicken or salmon
  const cats = await customerApi.getCats();
  const totalAllergies = Object.values(cats.cats).flatMap((cat) => cat.cat.medicalConditionsDetails?.split(",") ?? []);
  const normalisedAllergies = totalAllergies.map((a) => a.trim().toLowerCase());

  const hasChickenAllergy = normalisedAllergies.includes("chicken");
  const hasSalmonAllergy = normalisedAllergies.includes("salmon");

  if (!hasChickenAllergy) {
    return "chicken";
  } if (!hasSalmonAllergy) {
    return "salmon";
  }

  // If the user is allergic to both, return none
  return "none";
}
