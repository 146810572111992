"use client";

import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "next/navigation";

import { OrdersAPI } from "@api-clients/orders";
import { CustomerAPI } from "@api-clients/customer";
import { PublicConfig } from "apps/website/config/public";
import { useAuth } from "@auth/client-sdk-react";
import {
  checkNibblesUpsellEligibility,
} from "apps/website/utils/website/nibbles-upsell-eligibility";

import CrossSellModal, {
  NibblesPurchaseState,
} from "../feature/Modal/CrossSellModal/CrossSellModal";

type NibblesUpsellType = "chicken" | "salmon" | "none";

/**
 * The main query param that triggers a local check for Nibbles Upsell
 * (i.e., we'll call checkNibblesUpsellEligibility if present).
 */
export const NIBBLES_UPSELL_QUERY_KEY = "showNibblesPopUpFromPath";

/**
 * The param used when the server has ALREADY confirmed eligibility
 * and wants to directly tell us which flavour (chicken or salmon).
 * If this is present, we skip local checks and just show that flavour's modal.
 * This should ONLY be set by a server redirect after it has verified eligibility.
 */
export const NIBBLES_UPSELL_OVERRIDE_KEY = "upsellType";

const NibblesUpsell: FC = () => {

  const searchParams = useSearchParams();
  const [ canShowModal, setCanShowModal ] = useState<NibblesUpsellType>("none");
  const [ addNibblesResult, setAddNibblesResult ] = useState<NibblesPurchaseState>("none");
  const [ isModalOpen, setIsModalOpen ] = useState(true);

  const { accessToken } = useAuth();

  const customerApi = useMemo(() => new CustomerAPI(
    PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL,
    accessToken,
    { withCredentials: true },
  ), [ accessToken ]);

  const ordersApi = useMemo(() => new OrdersAPI(
    PublicConfig.NEXT_PUBLIC_ORDERS_BASE_URL,
    accessToken,
  ), [ accessToken ]);

  const handleOnConfirm = useCallback(async (nibblesType: NibblesUpsellType) => {
    if (!customerApi) {
      console.log("Customer api not found");
      return;
    }

    const productTitle = nibblesType === "chicken" ? "NIBBLES_CHICKEN" : "NIBBLES_SALMON";
    try {
      setAddNibblesResult("processing");
      const response = await customerApi.addNibblesTrial({ productTitle });
      if (response) {
        setAddNibblesResult("purchased");
      } else {
        setAddNibblesResult("failure");
      }
    } catch (error) {
      console.error("Error adding nibbles trial", error);
      setAddNibblesResult("failure");
    }
  }, [ customerApi ]);

  useEffect(() => {
    const determineIfModalShouldBeShown = async () => {
      if (!accessToken || !customerApi || !ordersApi) return;
      // Check if the server has already determined the flavour.
      const serverSelectedFlavour = searchParams.get(NIBBLES_UPSELL_OVERRIDE_KEY) as NibblesUpsellType;
      if (serverSelectedFlavour === "chicken" || serverSelectedFlavour === "salmon") {
        setCanShowModal(serverSelectedFlavour);
      } else if (searchParams.has(NIBBLES_UPSELL_QUERY_KEY)) {
        // If the server has not determined the flavour, check locally.
        const upsellType = await checkNibblesUpsellEligibility(customerApi, ordersApi, {
          minBoxesRequired: 1,
        });
        setCanShowModal(upsellType);
      }
    };

    void determineIfModalShouldBeShown();
  }, [ accessToken, customerApi, ordersApi, searchParams ]);

  return (
    <>
      { canShowModal === "chicken" && (
        <CrossSellModal
          id="nibbles-upsell"
          title="Get 50% off Chicken Nibbles"
          subtitle="Delicious treats your cat will go wild for."
          image={ {
            src: "https://a.storyblok.com/f/176726/1702x1000/cdacc77795/chicken-nibbles_2.png",
            width: 500,
            height: 300,
          } }
          addToOrderButtonText="Add to box"
          footerText="Add these to your regular plan. Remove anytime."
          isOpen={ isModalOpen }
          theme="light-grey"
          modalTitleId="nibbles-upsell-title"
          offerTitle=""
          offerBody="Add to your next box for only £3.75. Ongoing price £7.50."
          items={ [
            {
              image: {
                src: "https://a.storyblok.com/f/176726/500x500/71bf8da7c7/premium-meat-chicken.svg",
                width: 43,
                height: 43,
              },
              title: "100% Meat",
              titleSize: "sm",
            },
            {
              image: {
                src: "https://a.storyblok.com/f/176726/500x500/699f8d3ca9/kk-icons-other_uk.svg",
                width: 43,
                height: 43,
              },
              title: "Made in the UK",
              titleSize: "sm",

            },
            {
              image: {
                src: "https://a.storyblok.com/f/176726/500x500/a26fc9a500/for-all-ages.svg",
                width: 43,
                height: 43,
              },
              title: "For all ages",
              titleSize: "sm",

            },
            {
              image: {
                src: "https://a.storyblok.com/f/176726/500x500/c93c51a382/no-nasties.svg",
                width: 43,
                height: 43,
              },
              title: "No nasties",
              titleSize: "sm",

            },
          ] }
          review={ {
            items: [
              {
                avatar: {
                  src: "https://a.storyblok.com/f/176726/290x435/9fe8e858fe/magdalena_icon_scaled.jpeg",
                  width: 500,
                  height: 500,
                },
                body: "She's by my feet every time she hears the nibbles.",
                author: "Magdalena + Luna",
                theme: "brand",
              },
            ],
          } }
          onConfirm={ () => handleOnConfirm("chicken") }
          onClose={ () => setIsModalOpen(false) } modalDescriptionId={ "" }
          result={ addNibblesResult }
          successImage={ {
            src: "https://a.storyblok.com/f/176726/150x150/8f369a4b79/katkin_thescratchingpost_january_illus-1.svg",
            width: 500,
            height: 300,
          } }
          cardTag="50% off"
        />
      ) }
      {
        canShowModal === "salmon" && (
          <CrossSellModal
            id="nibbles-upsell"
            title="Get 50% off Salmon Nibbles"
            subtitle="Delicious treats your cat will go wild for."
            image={ {
              src: "https://a.storyblok.com/f/176726/1702x1000/bbe5fb5b7c/salmon-nibbles_2.png",
              width: 500,
              height: 300,
            } }
            addToOrderButtonText="Add to box"
            footerText="Add these to your regular plan. Remove anytime."
            isOpen={ isModalOpen }
            theme="light-grey"
            modalTitleId="nibbles-upsell-title"
            offerTitle=""
            offerBody="Add to your next box for only £4.75. Ongoing price £9.50."
            items={ [
              {
                image: {
                  src: "https://a.storyblok.com/f/176726/500x500/35461c7e2b/premium-meat-fish.svg",
                  width: 43,
                  height: 43,
                },
                title: "100% Salmon",
                titleSize: "sm",
              },
              {
                image: {
                  src: "https://a.storyblok.com/f/176726/500x500/699f8d3ca9/kk-icons-other_uk.svg",
                  width: 43,
                  height: 43,
                },
                title: "Made in the UK",
                titleSize: "sm",

              },
              {
                image: {
                  src: "https://a.storyblok.com/f/176726/500x500/a26fc9a500/for-all-ages.svg",
                  width: 43,
                  height: 43,
                },
                title: "For all ages",
                titleSize: "sm",

              },
              {
                image: {
                  src: "https://a.storyblok.com/f/176726/500x500/c93c51a382/no-nasties.svg",
                  width: 43,
                  height: 43,
                },
                title: "No nasties",
                titleSize: "sm",

              },
            ] }
            review={ {
              items: [
                {
                  avatar: {
                    src: "https://a.storyblok.com/f/176726/290x435/9fe8e858fe/magdalena_icon_scaled.jpeg",
                    width: 500,
                    height: 500,
                  },
                  body: "She's by my feet every time she hears the nibbles.",
                  author: "Magdalena + Luna",
                  theme: "brand",
                },
              ],
            } }
            onConfirm={ () => handleOnConfirm("salmon") }
            onClose={ () => setIsModalOpen(false) } modalDescriptionId={ "" }
            result={ addNibblesResult }
            successImage={ {
              src: "https://a.storyblok.com/f/176726/150x150/8f369a4b79/katkin_thescratchingpost_january_illus-1.svg",
              width: 500,
              height: 300,
            } }
            cardTag="50% off"
          />
        )
      }
    </>
  );

};

export default NibblesUpsell;
