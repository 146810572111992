import { OrdersAPI } from "@api-clients/orders";

export class OrdersState {

  /**
   * A class to determine the status of a customer's orders.
   * @param ordersClient A client for the orders API.
   * @returns An instance of the OrdersState class.
   * @example
   * const ordersState = new OrdersState(ordersClient);
   * const hasPurchasedNibbles = await ordersState.hasPurchasedNibblesInXMonths(3);
   */
  constructor(
    private readonly ordersClient: OrdersAPI,
  ) { }

  public async hasPurchasedNibblesInXMonths(months: number): Promise<boolean> {
    const data = await this.ordersClient.hasPurchasedNibblesInXMonths(months);
    return data.hasPurchasedNibbles;
  }

}
