import { BaseAPI } from "@utils/base-api";

export interface HasNibblesRecentlyResponse {
  hasPurchasedNibbles: boolean;
  lastOrderDate: string | null;
}

export class OrdersAPI extends BaseAPI {

  public async hasPurchasedNibblesInXMonths(months: number): Promise<HasNibblesRecentlyResponse> {
    const result = await this.axios.get<HasNibblesRecentlyResponse>(
      "/orders/nibbles/purchased-within",
      {
        params: { months },
      },
    );
    return result.data;
  }

}
